import axios from "@/plugins/axios.js";

class apiWrapper {
    static async checkNewUser() {
        return await axios.get('/account/newaccount');
    }

    static async getAccount(userEmail) {
        const queryParams = { userEmail: userEmail };
        return await axios.get("/account", { params: queryParams });
    }
    
    static async getUsers(){
        return await axios.get("/user");
    }

    static async putEnable(userEmail, name){
        const queryParams = {
            Email: userEmail,
            Name: name,
            IsEnabled: true
        };
        return await axios.put('/user', queryParams);                
    }

    static async putDisable(userEmail, name){
        const queryParams = {
            Email: userEmail,
            Name: name,
            IsEnabled: false
        };
        return await axios.put('/user', queryParams);    
    }

    static async getProficiencies(userEmail) {
        const queryParams = {
            userEmail: userEmail
        };
        return await axios.get("/proficiency", { params: queryParams });
    }
    
    static async getAllProficiencies() {
        return await axios.get('/proficiency/all');
    }

    static async getProficienciesBySkills(skillIds) {
        return await axios.post('/proficiency/getbyskill', skillIds);
    }

    static async putProficiency(proficiency) {
        return await axios.post('/proficiency', proficiency);
    }

    static async patchProficiencyReviewDate(proficiency){
        return await axios.patch('/proficiency/review', proficiency);
    }

    static async postProficiency(proficiency) {
        return await axios.post('/proficiency', proficiency);
    }

    static async deleteProficiency(proficiency) {
        const queryParams = {
            Id: proficiency.id
        }
        return await axios.delete('/proficiency', {query: queryParams});
    }

    static async getSkills() {
        return await axios.get('/skill');
    }

    static async postSkill(skill){
        return await axios.post('/skill', skill);
    }

    static async putSkill(skill){
        return await axios.put("/skill", skill);
    }

    static async mergeSkills(newSkill, skills){
        const queryParams = {
            NewSkill: newSkill,
            SkillsToMerge: skills
        };

        return await axios.put('/mergeskills', queryParams);
    }

    static async getEngineers() {
        return await axios.get('/engineer');
    }
    
    static async getTags(){
        return await axios.get('/tag');
    }

    static async postTag(tag) {
        return await axios.post('/tag', tag);
    }

    static async putTag(tag){
        return await axios.put('/tag', tag);
    }

    static async getAvailableRoles() {
        return await axios.get('/role');
    }

    static async getRoles(userEmail) {
        const queryParams = {
            userEmail: userEmail
        };
        return await axios.get('/userrole', {params: queryParams});
    }

    static async postUserRole(roleName, userEmail) {
        const body = {
            role: roleName,
            email: userEmail
        };
        return await axios.post('/userrole', body);
    }

    static async deleteUserRole(roleName, userEmail) {
        const queryParams = {
            role: roleName,
            email: userEmail
        };

        return await axios.delete('/userrole', {data: queryParams});
    }

    static getLunches() {
        return axios.get('/lunchSchedule');
    }

    static getLunchesWithAccessToken(accessToken) {
        const uninterceptedAxiosInstance = axios.create();
        return uninterceptedAxiosInstance.get(`${process.env.VUE_APP_API_URL}/lunchSchedule/accessToken?accessToken=${accessToken}`);
    }

    static async uploadLunches(lunchBase64){
        let body = { csvBytes: lunchBase64 };
        return await axios.post('/lunchSchedule/upload', body);
    }

    static addLunch(lunch){
        return axios.post('/lunchSchedule', lunch);
    }

    static editLunch(lunch){
        return axios.put('/lunchSchedule', lunch);
    }

    static deleteLunch(lunchId) {
        return axios.delete(`/lunchSchedule?id=${lunchId}`);
    }

    static async getImages() {
        return await axios.get('/dashboardImages');
    }

    static async getImagesWithAccessToken(accessToken){
        const uninterceptedAxiosInstance = axios.create();

        return await uninterceptedAxiosInstance.get(`${process.env.VUE_APP_API_URL}/dashboardImages/AccessToken?accessToken=${accessToken}`);
    }

    static async uploadImages(images){
        return await axios.post('/dashboardImages', images);
    }

    static async deleteImage(imageId){
        return await axios.delete(`/dashboardImages?imageId=${imageId}`);
    }

    static async getDashboardConfig(){
        return await axios.get('/config');
    }

    static async getDashboardConfigWithAccessToken(accessToken){
        const uninterceptedAxiosInstance = axios.create();

        return await uninterceptedAxiosInstance.get(`${process.env.VUE_APP_API_URL}/config/AccessToken?accessToken=${accessToken}`);
    }

    static async putDashboardConfig(config){
        return await axios.put('/config', config);
    }

    static async getDashboardTokens(){
        return await axios.get('/dashboardaccesstokens');
    }

    static async createDashboardToken(tokenDescription){
        let data = { description: tokenDescription };
        return await axios.post('/dashboardaccesstokens', data);
    }

    static async deleteDashboardToken(tokenId){
        return await axios.delete(`/dashboardaccesstokens?tokenId=${tokenId}`);
    }

    static async postSkillsCSV(fileData){
        return await axios.post('/import/skillsCSV', JSON.stringify(fileData), { headers: { 'Content-Type': 'application/json' }});
    }
    
    static async postProficienciesCSV(fileData){
        return await axios.post('/import/proficienciesCSV', JSON.stringify(fileData), { headers: { 'Content-Type': 'application/json' }});
    }

    static async getResumeSharingLink(engineerName){
        let queryParams = {
            engineerName: engineerName,
            linkType: "email"
        };

        return await axios.get('/resumelink', { params: queryParams });
    }

    static async getResumeDownloadLink(engineerName) {
        let queryParams = {
            engineerName: engineerName,
            linkType: "download"
        };

        return await axios.get('/resumelink', {params: queryParams});
    }

    static async uploadMicrosoftCerts(csvBytes) {
        let body = {
            csvBytes: csvBytes
        };
        return await axios.post('/certification/microsoft', body);
    }

    static async getCertifications(userId) {
        return await axios.get(`/certification?userId=${userId}`);
    }

    static async getSeatAssignments(floorplanId) {
        return await axios.get(`/seatassignments?floorplanId=${floorplanId}`);
    }

    static async getSeatLayout() {
        return await axios.get('/seatlayout');
    }

    static async getAllFloorplans() {
        return await axios.get('/floorplans');
    }

    static async getCurrentFloorplan() {
        return await axios.get('/currentfloorplan');
    }

    static async getAllComments() {
        return await axios.get(`/comments`);
    }

    static async deleteComment(comment) {
        return await axios.delete('/deleteComment', {data: comment})
    }

    static async saveNewComment(commentInfo) {
        return await axios.put('/saveNewComment', commentInfo);
    }

    static async getSeatingUsers() {
        return await axios.get('/employee');
    }

    static async renameFloorplanById(floorplan) {
        return await axios.patch('/renameFloorplan', floorplan);
    }

    static async deleteFloorplanById(floorplanId) {
        return await axios.delete(`/deletefloorplan?floorplanId=${floorplanId}`);
    }

    static async updateFloorplanApproval(floorplan) {
        return await axios.patch('/updateapproval', floorplan);
    }

    static async updateFloorplanApprovalRequest(floorplan) {
        return await axios.patch('/requestapproval', floorplan);
    }

    static async updatePublishedFloorplan(floorplan) {
        return await axios.patch('/updatepublished', floorplan);
    }

    static async saveFloorplanByIdAPI(floorplan) {
        return await axios.put('/savefloorplan', floorplan);
    }

    static async saveFlooplanSeatsAPI(deskLayout) {
        return await axios.put('/saveseats', deskLayout);
    }

    static async insertNewFloorplanSeatsAPI(seatList) {
        return await axios.post('/newseats', seatList);
    }

    static async createFloorplan(floorplan) {
        return await axios.put('/newfloorplan', floorplan);
    }

    static async getFloorplanApprovals(floorplanId) {
        return await axios.get(`/floorplanApprovals?floorplanId=${floorplanId}`);
    }

    static async addFloorplanApproval(floorplanId, userId) {
        return await axios.put(`/addFloorplanApproval`, {FloorplanId: floorplanId, UserId: userId});
    }

    static async deleteFloorplanApproval(floorplanId, userId) {
        return await axios.delete(`/deleteFloorplanApproval`, {data: {FloorplanId: floorplanId, UserId: userId}});
    }

    static async deleteAllFloorplanApprovals(floorplanId) {
        return await axios.delete(`/deleteAllFloorplanApprovals`, {data: {FloorplanId: floorplanId}});
    }
}

export default apiWrapper;