<template>
  <v-app id="app">
    <navbar v-if="!this.$route.query.accessToken" />
    <v-main>
      <router-view :key="$route.fullPath"/>
    </v-main>
    <RefreshModal id="update-available" :title="'Update Available'" :subTitle="'New content is available, please refresh'" />
    <RefreshModal id="session-expired" :title="'Session Expired'" :subTitle="'Refresh to continue.'"/>
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import RefreshModal from '@/components/RefreshModal.vue'

export default {
  components: {
    Navbar,
    RefreshModal
  }
}
</script>

<style>
@media screen and (min-width:1262px) {
  html.kiosk-mode {
    overflow: hidden;
  }
}
.primary--text {
    color: white !important;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  overflow-x: hidden;
  background-color: #fafafa;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #663399;
}

#nav a.router-link-exact-active {
  color: blueviolet;
}

.title-bar{
  text-align: left;
  box-shadow: 0px 0px 5px #999999;
  padding: 16px;
  padding-left: 30px;
  color: #444444;
  background-color: white;
  z-index: 10;
  display: flex;
  align-content: center;
  flex-direction: row;
}


@media screen and (max-height: 900px) {
  .v-select-list.v-card{
    max-height: 110px;
  }
}

</style>
