<template>
  <div class="update-modal">
    <div class="refresh-dialog">
      <h1>{{title}}</h1>
      <p>{{subTitle}}</p>
      <v-btn class="white--text" :color="colors.PRIMARY" @click="reloadPage()">
        Refresh 
        <v-icon class="refresh-icon">refresh</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import colors from "@/colors";
export default {
  name: "updateavailable",
  data() {
    return {
      colors
    }
  },
  props: [ "title", "subTitle" ],
  methods: {
    reloadPage(){
      location.reload();
    }
  }
}
</script>

<style scoped>
.update-modal{
  position: absolute;
  top: 1;
  bottom: 1;
  left: 1;
  right: 1;
  background: rgba(0,0,0,0.7);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 200px;
  display: none;
}

.refresh-dialog{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 300px;
  height: 200px;
  padding: 5px;
  border-radius: 5px;
}

.refresh-icon{
  margin-left: 10px;
}
</style>