import Vue from 'vue';
import Vuex from 'vuex';
import {DashboardConfigModule} from "@/store/modules/dashboardConfigModule.js";
import {DashboardTokenModule} from "@/store/modules/dashboardTokenModule.js";
import {EngineerModule} from "@/store/modules/engineerModule.js";
import {ImageModule} from "@/store/modules/imageModule.js";
import {LunchModule} from "@/store/modules/lunchModule.js";
import {ProficiencyModule} from "@/store/modules/proficiencyModule.js";
import {RoleModule} from "@/store/modules/roleModule.js";
import {SeatingChartModule} from "@/store/modules/seatingChartModule.js";
import {SkillModule} from "@/store/modules/skillModule.js";
import {TagModule} from "@/store/modules/tagModule.js";
import {UserCertificationModule} from "@/store/modules/userCertificationModule.js";
import {UserModule} from "@/store/modules/userModule.js";
import {NavbarDisplayModule} from "@/store/modules/navbarDisplayModule.js";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    DashboardConfigModule,
    DashboardTokenModule,
    EngineerModule,
    ImageModule,
    LunchModule,
    ProficiencyModule,
    RoleModule,
    SeatingChartModule,
    SkillModule,
    TagModule,
    UserCertificationModule,
    UserModule,
    NavbarDisplayModule
  }
})
