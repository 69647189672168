import store from "@/store";
import _ from 'lodash';

class roleIdentifier {
    static isUserViewingEngineer(){
        return _.find(store.getters.userViewingRoles, (role) => role === "Engineer") !== undefined;
    }

    static amIEngineer(){
        return _.find(store.getters.myRoles, (role) => role === "Engineer") !== undefined;
    }

    static amIAdmin(){
        return _.find(store.getters.myRoles, (role) => role === "Admin") !== undefined;
    }

    static amIDashboardAdmin(){
        return _.find(store.getters.myRoles, (role) => role === "BreakroomDashboardAdmin") !== undefined;
    }

    static amICertificationManager(){
        return _.find(store.getters.myRoles, (role) => role === "CertificationManager") !== undefined;
    }
}

export default roleIdentifier;