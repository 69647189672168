import authenticator from "@/authenticator.js";

class graphApiWrapper {
  tokenRequest = {
    scopes: ["https://graph.microsoft.com/.default"]
  };

  graphConfig = {
    getMeEndpoint: process.env.VUE_APP_GRAPH_API_URI + "me",
    getPhotoEndpoint: process.env.VUE_APP_GRAPH_API_URI + "me/photos/photo-size-here/$value",
    getDropinEndPoint: process.env.VUE_APP_GRAPH_API_URI + "users/desk-user-id/calendar/events",
    postFindMeetingTimes: process.env.VUE_APP_GRAPH_API_URI + "me/calendar/getschedule",
    postCalendarRes: process.env.VUE_APP_GRAPH_API_URI + "me/events",
  };

  deskMap = {
    'DropInDesk1': 'dropindesk1@omnitech-inc.com',
    'DropInDesk2': 'dropindesk2@omnitech-inc.com',
    'DropInDesk3': 'dropindesk3@omnitech-inc.com'
  };
  
  auth = new authenticator();

  // Line breaks are for legibility only.

  async getDropInEvents() {

    let authToken = await this.auth.getToken(this.tokenRequest);

    let dropInId;

  
    dropInId = "d6f46928-1da0-44c3-9ec8-d01fc97d0934";

    const url = this.graphConfig.getDropinEndPoint.replace('desk-user-id', dropInId);

    return await this.callGraphAPI('GET', url, authToken);
  }

  async getProfileImage(size) {

    let authToken = await this.auth.getToken(this.tokenRequest);

    const url = this.graphConfig.getPhotoEndpoint.replace('photo-size-here', size);

    return await this.callGraphAPI('GET', url, authToken, null, 'blob');
  }

  async checkDropInAvailability(type, startTime, endTime, desk) {
    desk = desk.replace(/\s+/g, '')
    const deskEmail = this.deskMap[desk];
    let proposedReservation = {
      "Schedules": [deskEmail],
      "StartTime": {
          "dateTime": startTime,
          "timeZone": "Central Standard Time"
      },
      "EndTime": {
          "dateTime": endTime,
          "timeZone": "Central Standard Time"
      },
      "availabilityViewInterval": "15"
    }

    const authToken = await this.auth.getToken(this.tokenRequest);
    const response = await this.callGraphAPI('POST', this.graphConfig.postFindMeetingTimes, authToken, proposedReservation);

    if (response.value[0].availabilityView.includes('0'))
      return true
    else
      return false;
  }

  async reserveDropInSeat(startDate, endDate, location, user) {
    let name = 'Reservation for ' + user;
    let bodyLocation = location
    location = location.replace(/\s+/g, '')

    
    const locationEmail = this.deskMap[location];

    let meetingData = {
      "subject": name,
      "body": {
        "contentType": "HTML",
        "content": bodyLocation + " Reserved from " + startDate + " to " + endDate
      },
      "start": {
        "dateTime": startDate,
        "timeZone": "Central Standard Time"
      },
      "end": {
        "dateTime": endDate,
        "timeZone": "Central Standard Time"
      },
      "location": {
        "displayName" : location,
        "locationEmailAddress" : locationEmail,
      },
      "attendees": [{
        "emailAddress": {
          "address": locationEmail,
          "name": location
        },
        "type": "required"
      }],
      "ShowAs": "Free",
      "IsReminderOn": false,
    }

    let authToken = await this.auth.getToken(this.tokenRequest);
    let response = await this.callGraphAPI('POST', this.graphConfig.postCalendarRes, authToken, meetingData);
    return response;
  }

  callGraphAPI(method, endpoint, token, data = null, type = 'Json') {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    const body = JSON.stringify(data);
    const successCodes = [200, 201, 202];

    headers.append('Authorization', bearer);

    const options = {
      method: '',
      headers: headers
    };

    options.method = method.toUpperCase();

    if (options.method === 'POST') {
      headers.append('Content-Type', 'application/json');
      options.body = body;
    }

    switch (type.toLowerCase()) {
      case 'json':
        return fetch(endpoint, options)
          .then(response => {
            if (!successCodes.includes(response.status)) {
              throw response;
            }

            return response.json();
          })
      case 'blob':
        return fetch(endpoint, options)
          .then(response => response.blob())
    }
  }
}


export default graphApiWrapper;