import graphApiWrapper from "@/graphApiWrapper";

class profilePictureDataAccess {

  async getProfilePicture() {
    const graph = new graphApiWrapper();

    let response = await graph.getProfileImage("120x120");
    
    if (response.data !== undefined) {
      return await this.readProfileBlob(response.data);
    } else {
      return null;
    }
  }

  async readProfileBlob(blob) {
    var readerPromise = new Promise((resolve) => {
      var reader = new FileReader();
      
      reader.addEventListener("load", () => {
        resolve(reader.result);
      }, false);

      if (blob) {
        reader.readAsDataURL(blob);
      }
    });

    return await readerPromise;
  }
}

export default profilePictureDataAccess;