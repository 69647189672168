export const UserCertificationModule =  {
  state: {
      certifications: []
  },
  mutations: {
      setCertifications(state, certifications) {
          state.certifications.splice(0, state.certifications.length);
          state.certifications.push(...certifications);
      }
  },
  getters: {
      certifications: state => {
          return state.certifications;
      }
  }
}