export const DashboardTokenModule = {
  state: {
    dashboardAccessTokens: []
  },
  mutations: {
    createDashboardToken(state, newToken){
      state.dashboardAccessTokens.push(newToken);
    },
    setDashboardTokens(state, tokens){
      state.dashboardAccessTokens.splice(0, state.dashboardAccessTokens.length);
      state.dashboardAccessTokens.push(...tokens);
    },
    deleteDashboardToken(state, tokenId){
      for(var i = 0; i < state.dashboardAccessTokens.length; i++){
        if(state.dashboardAccessTokens[i].id === tokenId){
          state.dashboardAccessTokens.splice(i, 1);
          return;
        }
      }
    }
  },
  getters: {
    dashboardTokens: state => {
      return state.dashboardAccessTokens;
    }
  }
}