export const LunchModule =  {
  state: {
      lunches: []
  },
  mutations: {
      setLunches(state, lunches){
        state.lunches.splice(0, state.lunches.length);
        state.lunches.push(...lunches);
      },
      addLunch(state, lunch) {
        state.lunches.push(lunch);

        state.lunches.sort((a,b) => {
            if (a.eatDate < b.eatDate) {
                return -1;
            }
            if (a.eatDate > b.eatDate) {
                return 1;
            }
            return 0;
        })
      },
      removeLunch(state, lunchId) {
        let lunch = state.lunches.find(l => l.id === lunchId);
        if (lunch) {
            let index = state.lunches.indexOf(lunch);
            state.lunches.splice(index, 1);
        }
      }
  },
  getters: {
      lunchList: state => {
          return state.lunches;
      }
  },
  actions: {
      addLunch({commit}, lunch) {
        commit("addLunch", lunch);
      },
      removeLunch({commit}, lunchId) {
        commit("removeLunch", lunchId);
      }
  } 
}