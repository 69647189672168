import * as msal from "@azure/msal-browser";
class authenticator {
  constructor() {
    this.msalConfig = {
      auth: {
        clientId: process.env.VUE_APP_AD_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${process.env.VUE_APP_AD_TENANT_ID}/`,
        redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI,
        navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
      },
      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case msal.LogLevel.Error:
                console.error(message); 
                return;
              case msal.LogLevel.Info:
                // console.info(message);
                return;
              case msal.LogLevel.Verbose:
                console.debug(message);
                return;
              case msal.LogLevel.Warning:
                console.warn(message);
                return;
            }
          }
        }
      }
    };

    this.loginRequest = {
      scopes: [`${process.env.VUE_APP_AD_CLIENT_ID}/.default`]
    };

    this.tokenRequst = {
      scopes: ["User.Read"]
    };

    this.myMSALObj = new msal.PublicClientApplication(this.msalConfig);

    this.username = "";
  }


  startUp() {
    return new Promise((resolve, reject) => {
      if (window.location.href.includes('accessToken')) {
         resolve();
         return;
      }

      this.myMSALObj.handleRedirectPromise()
        .then((resp) => {
          if (resp !== null) {
            this.username == resp.account.username;
          } else {
            if (!this.getIsAuthenticated()) {
              this.handleSignOn();
              reject();
              return;
            }
          }
          resolve();
        })
        .catch((error) => {
          console.error(error);
          this.handleSignOn();
          reject();
        });
    });
  }

  /// MSAL 

  getIsAuthenticated() {
    const accounts = this.myMSALObj.getAllAccounts();
    return accounts && accounts.length > 0;
  }

  handleSignOn() {
    try {
      this.myMSALObj.loginRedirect(this.loginRequest);
    } catch (error) {
      console.log(error);
    }
  }

  signOut() {
    try {
      const logoutRequest = {
        account: this.myMSALObj.getAccountByUsername(this.username)
      };

      this.myMSALObj.logoutRedirect(logoutRequest);
    } catch (error) {
      console.log(error);
    }
  }

  getToken(request) {
    request.account = this.myMSALObj.getAllAccounts()[0];
    return this.myMSALObj.acquireTokenSilent(request)
      .then((response) => {
        if (response.accessToken) {
          return response.accessToken;
        }
      })
      .catch(error => {
        console.log("silent token acquisition fails. acquiring token using redirect");
        // fallback to interaction when silent call fails
        console.log(error);
        return this.myMSALObj.acquireTokenRedirect(request);
      });
  }


  async getMSALToken() {
    try {
      this.loginRequest.account = this.myMSALObj.getAllAccounts()[0];
      return await this.myMSALObj.acquireTokenSilent(this.loginRequest);
    } catch (error) {
      console.log(error);
      if (error instanceof msal.InteractionRequiredAuthError) {
        return this.myMSALObj.acquireTokenRedirect(this.loginRequest)
      }
    }
  }
}

export default authenticator