export const SeatingChartModule =  {
    //namespaced: true, // for later implimentation 
    state: {
        floorplanSeats: [],
        seatDetails: [],
        floorplans: [],
        employees: [],
        comments: [],
        selectedFloorplan: ''
    },
    mutations: {
        updateFloorplanSeatsList(state, seats) {
            state.floorplanSeats = seats;
        },
        updateFloorplansList(state, floorplans) {
            state.floorplans = floorplans;
        },
        updateEmployeeList(state, employees) {
            state.employees = employees;
        },
        updateCommentList(state, comments) {
            state.comments = comments;
        },
        updateSeatDetailsList(state, seats) {
            state.seatDetails = seats;
        },
        updateSelectedFloorplan(state, floorplan) {
            state.selectedFloorplan = floorplan;
        }
    },
    getters: {
        floorplanSeatsList: state => {
            return state.floorplanSeats;
        },
        floorplansList: state => {
            return state.floorplans;
        },
        employeeList: state => {
            return state.employees;
        },
        commentList: state => {
            return state.comments;
        },
        seatDetailsList: state => {
            return state.seatDetails;
        },
        currentFloorplan: state => {
            return state.selectedFloorplan;
        }
    }
}