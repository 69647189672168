export const ProficiencyModule =  {
    state: {
        allProficienciesList: [],
        proficiencyList: [],
        proficienciesBySkill: []
    },
    mutations: {
        addProficiency(state, proficiency){
            state.proficiencyList.push(proficiency);
            state.allProficienciesList.push(proficiency);
        },
        updateProficiency(state, proficiency){
            state.proficiencyList.forEach(element => {
                if(element.id == proficiency.id){
                    element.level = proficiency.level;
                    element.modifiedDate = proficiency.modifiedDate;
                    element.reviewedDate = proficiency.reviewedDate;
                    element.createdDate = proficiency.createdDate;
                }
            });
        },
        updateProficiencyList(state, proficiencies){
            state.proficiencyList.splice(0, state.proficiencyList.length);
            state.proficiencyList.push(...proficiencies);
        },
        updateAllProficienciesList(state, proficiencies){
            state.allProficienciesList.splice(0, state.allProficienciesList.length);
            state.allProficienciesList.push(...proficiencies);
        },
        updateProficienciesBySkill(state, userProficiencies){
            state.proficienciesBySkill.splice(0, state.proficienciesBySkill.length);
            state.proficienciesBySkill.push(...userProficiencies);
        },
        updateProficiencyReviewDate(state, proficiency){
            state.proficiencyList.forEach(element => {
                if(element.id == proficiency.id){
                    element.reviewedDate = proficiency.reviewedDate;
                }
            });
        },
        deleteProficiency(state, proficiency){
            for(var i = 0; i < state.proficiencyList.length; i++){
                if(state.proficiencyList[i].id == proficiency.id){
                    state.proficiencyList.splice(i, 1);
                    return;
                }
            }
        }
    },
    getters: {
        allProficienciesList: state => {
            return state.allProficienciesList;
        },
        proficiencyList: state => {
            return state.proficiencyList;
        },
        proficiency: (state) => (proficiency) => {
            return state.proficiencyList.find(k => k.id == proficiency.id);
        },
        proficienciesBySkill: state => {
            return state.proficienciesBySkill;
        }
    }
}