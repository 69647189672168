export const ImageModule =  {
  state: {
    images: []
  },
  mutations: {
    setImages(state, images) {
      state.images.splice(0, state.images.length);
      state.images.push(...images);
    },
    addImage(state, images) {
      state.images.push(images);
    },
    removeImage(state, imageId) {
      for (var i = 0; i < state.images.length; i++) {
        if (state.images[i].id == imageId) {
          state.images.splice(i, 1);
          return;
        }
      }
    }
  },
  getters: {
    imageList: state => {
      return state.images;
    }
  }
}