import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'My Dashboard',
    component: () => import('@/views/Dashboard/Dashboard.vue')
  },
  {
    path: '/roles',
    name: 'Roles',
    component: () => import('@/views/Roles/Roles.vue')
  },
  {
    path: '/skills',
    name: 'Skills',
    component: () => import('@/views/Skills/Skills.vue')
  },
  {
    path: '/manageskills',
    name: 'Manage Skills',
    component: () => import('@/views/ManageSkills/ManageSkills.vue')
  },
  {
    path: '/manageusers',
    name: 'Manage Users',
    component: () => import('@/views/ManageUsers/ManageUsers.vue')
  },
  {
    path: '/searchbyskill',
    name: 'Search By Skill',
    component: () => import('@/views/SearchBySkill/SearchBySkill.vue')
  },
  {
    path: '/searchforengineers',
    name: 'Search For Engineers',
    component: () => import('@/views/SearchForEngineers/SearchForEngineers.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('@/views/Account/Account.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('@/views/Logout/Logout.vue')
  },
  {
    path: '/skillsurvey',
    name: 'Skill Survey',
    component: () => import('@/views/SkillSurvey/SkillSurvey.vue')
  },
  {
    path: '/skillreview',
    name: 'Skill Review',
    component: () => import('@/views/SkillReview/SkillReview.vue')
  },
  {
    path: '/skillcleanup',
    name: 'Skill Cleanup',
    component: () => import('@/views/SkillCleanup/SkillCleanup.vue')
  },
  {
    path: '/certificationmanagement',
    name: 'Certification Management',
    component: () => import('@/views/CertificationManagement/CertificationManagement.vue')
  },
  {
    path: '/certifications',
    name: 'Certifications',
    component: () => import('@/views/Certifications/Certifications.vue')
  },
  {
    path: '/breakroomdashboard/board',
    name: 'Breakroom Dashboard',
    component: () => import('@/views/BreakroomDashboard/BreakroomDashboard.vue')
  },
  {
    path: '/breakroomdashboard/control',
    name: 'Breakroom Control Panel',
    component: () => import('@/views/BreakroomControlPanel/BreakroomControlPanel.vue')
  },
  {
    path: '/seatingchart',
    name: 'Seating Chart',
    component: () => import('@/views/SeatingChart/SeatingChart.vue')
  },
  {
      path: '/editseatingchart',
      name: 'Edit Seating Chart',
      component: () => import('@/views/SeatingChart/ManageSeatingChart.vue')
  }
];


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
