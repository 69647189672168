import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: '#432580',
        accent: '#FF4081',
        secondary: '#FFE18D',
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FB8C00',
        error: '#FF5252',
        navMenu: '#432580'
      },
      light: {
        primary: '#331570',
        accent: '#E91E63',
        secondary: '#30b1dc',
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FB8C00',
        error: '#FF5252',     
        navMenu: '#432580',
      }
    },
  },
    icons: {
        iconfont: 'mdi',
      },
});
