export const TagModule =  {
    state: {
        tags: []
    },
    mutations: {
        updateTags(state, tags) {
            state.tags.splice(0, state.tags.length);
            state.tags.push(...tags);
        },
        addTag(state, tag){
            state.tags.push(tag);
        },
        updateTag(state, tag){
            state.tags.forEach(element => {
                if(element.id == tag.id){
                    element.id = tag.id;
                    element.name = tag.name;
                    return;
                }
            });
        }
    },
    getters: {
        tagList: state => {
            return state.tags;
        },
        tag: (state) => (tag) => {
            return state.tags.find(k => k == tag);
        }
    }
}