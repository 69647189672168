import apiWrapper from "@/apiWrapper";
import store from "@/store";

class userDataAccess {
    static cacheTimeoutMilliseconds = 1000 * 60 * 5;
    static lastGetAccountUpdate = -1;
    static lastAccountRetrieved = "";
    static lastGetUsersUpdate = -1;

    async getAccount(userEmail) {
        if(this.needsGetAccountRefresh(userEmail)){
            try{
                let response = await apiWrapper.getAccount(userEmail);
                
                userDataAccess.lastGetAccountUpdate = new Date().getTime();
                userDataAccess.lastAccountRetrieved = userEmail;

                if(userEmail == null){
                    store.commit("updateUser", response.data);
                }
                else {
                    store.commit("updateUserViewing", response.data);
                }
            }
            catch(err){
                if(userEmail == null){
                    return store.getters.user;
                }
                else {
                    return store.getters.userViewing;
                }
            }
        }
        
        if(userEmail == null){
            return store.getters.user;
        }
        else {
            return store.getters.userViewing;
        }
    }

    async getUsers() {
        if(this.needsGetUsersRefresh()){
            let response = await apiWrapper.getUsers();
            store.commit("updateAllUsers", response.data);
            userDataAccess.lastGetUsersUpdate = new Date().getTime();
        }
        
        return store.getters.allUsers;
    }

    async enableUser(userEmail, userName) {
        await apiWrapper.putEnable(userEmail, userName);
        store.commit("enableUserViewing");
    }

    async disableUser(userEmail, userName) {
        await apiWrapper.putDisable(userEmail, userName);
        store.commit("disableUserViewing");
    }

    needsGetAccountRefresh(userEmail){
        return userDataAccess.lastGetAccountUpdate === -1 || 
               userDataAccess.lastAccountRetrieved !== userEmail ||
               new Date().getTime() - userDataAccess.lastGetAccountUpdate >= userDataAccess.cacheTimeoutMilliseconds;
    }

    needsGetUsersRefresh(){
        return userDataAccess.lastGetUsersUpdate === -1 || 
               new Date().getTime() - userDataAccess.lastGetUsersUpdate >= userDataAccess.cacheTimeoutMilliseconds;
    }
}

export default userDataAccess;