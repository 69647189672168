export const NavbarDisplayModule = {
    state: {
        miniNavbar: false
    },
    mutations: {
        activateMiniNavbar(state) {
            state.miniNavbar = true;
        },
        setDisplayState(state, value) {
            state.miniNavbar = value;
        }
    },
    getters: {
        navbarDisplayState: state => {
            return state.miniNavbar;
        }
    }
}