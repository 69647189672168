export const RoleModule =  {
    state: {
        myRoles: [],
        userViewingRoles: [],
        availableRoles: []
    },
    mutations: {
        updateMyRoles(state, roles){
            state.myRoles.splice(0, state.myRoles.length);
            state.myRoles.push(...roles);
        },
        addMyRole(state, role){
            state.myRoles.push(role);
        },
        removeMyRole(state, role){
            for(var i = 0; i < state.myRoles.length; i++){
                if(state.myRoles[i] == role){
                    state.myRoles.splice(i, 1);
                }
            }        
        },
        updateUserViewingRoles(state, roles){
            state.userViewingRoles.splice(0, state.userViewingRoles.length);
            state.userViewingRoles.push(...roles);
        },
        addUserViewingRole(state, role){
            state.userViewingRoles.push(role);
        },
        removeUserViewingRole(state, role){
            for(var i = 0; i < state.userViewingRoles.length; i++){
                if(state.userViewingRoles[i] == role){
                    state.userViewingRoles.splice(i, 1);
                }
            }
        },
        updateAvailableRoles(state, roles){
            state.availableRoles.splice(0, state.availableRoles.length);
            state.availableRoles.push(...roles);
        },
    },
    getters: {
        myRoles: state => {
            return state.myRoles;
        },
        userViewingRoles: state => {
            return state.userViewingRoles;
        },
        availableRoles: state => {
            return state.availableRoles;
        },
        myRole: (state) => (role) => {
            return state.myRoles.find(k => k == role);
        },
        userViewingRole: (state) => (role) => {
            return state.userViewingRoles.find(k => k == role);
        },
    }
}