import Vue from 'vue'
import vuetify from './plugins/vuetify';
import twitter from 'vue-twitter'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import authenticator from './authenticator'
import apiWrapper from './apiWrapper';
import roleDataAccess from './dataAccess/roleDataAccess';
import userDataAccess from './dataAccess/userDataAccess';
import moment from 'moment';
import VueMask from 'v-mask'

Vue.config.productionTip = false;

Vue.filter("monthAndDate", date => moment(date).format('M/D'));
Vue.filter("shortDate", date => moment(date).format('M/DD/yyyy'));
Vue.filter("longDate", date => moment(date).format('MMMM DD, YYYY'));

Vue.use(twitter);
Vue.use(VueMask);

var auth = new authenticator();
auth.startUp().then(() => {

  new Vue({
    router,
    store,
    render: h => h(App),
    vuetify,

    created: async function () {
      if (window.location.href.includes('accessToken')) {
        return;
      }
      else {
        var userDAO = new userDataAccess();

        await apiWrapper.checkNewUser();
        await userDAO.getAccount();
  
        var roleDAO = new roleDataAccess();
        let response = '';
        try {
          response = await roleDAO.getRoles();
        } catch (err) {
          console.log(err);
        }
        if (response.length == 0) {
          router.push('/roles');
        }
      }
    }
  }).$mount('#app')
}).catch  ((error) => {
  console.log(error)
});