<template>
  <div>
    <div class="toolbar-container">
      <v-app-bar-nav-icon  @click="showSideMenu = !showSideMenu"></v-app-bar-nav-icon>
    </div>
    
    <v-navigation-drawer    
      v-model="showSideMenu"
      app
      :expand-on-hover="!isMini"
      width="325"
      :style="{'background-color': colors.PRIMARY}"  
    >
      <template v-slot:prepend>
        <v-list-item dark two-line class="pl-2">
          <v-list-item-avatar class="bordered">
            <img v-if="profileImage === null" src="../assets/omnitechlogo.png" />
            <img v-else :src="profileImage" />
          </v-list-item-avatar>

          <v-list-item-content v-if="user.name" class="text-left">
            <v-list-item-title>{{ user.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ userRoles[0] }}</v-list-item-subtitle>
          </v-list-item-content>
          
          <v-list-item-content v-else>
            <v-list-item-title>Logging In...</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>      

      <v-list dark>
          <v-list-group active-class="white--text">
            <v-icon slot="prependIcon">signal_cellular_alt</v-icon>
            
            <template v-slot:activator>
              <v-list-item-content color="primary">
                <v-list-item-title class="text-left">Skills Matrix</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list dense v-if="user.name != '' && userRoles.length != 0">
              <div v-if="isEngineer">
                <v-list-item
                  v-for="item in engineerNavItems"
                  :key="item.title"
                  @click="goToPage(item.url)"
                  
                >
                  <v-list-item-icon >
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title class="text-left">{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <div class="empty-list-space"></div>
              </div>

              <div v-if="isCertificationManager">
                <v-list-item v-for="item in certificationManagerNavItems" :key="item.title" @click="goToPage(item.url)">
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-left">{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <div v-if="!isAdmin" class="empty-list-space"></div>
              </div>
              <div v-if="isAdmin">
                <v-list-item v-for="item in adminSkillNavItems" :key="item.title" @click="goToPage(item.url)">
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-left">{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <div class="empty-list-space"></div>
              </div>
              <v-list-item v-for="item in allUserNavItems" :key="item.icon" @click="goToPage(item.url)">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title class="text-left">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-list-group>
          
          <!-- Breakroom Dashboard Admin Menu-->
          <v-list-group v-if="isDashboardAdministrator" active-class="white--text">
            <v-icon slot="prependIcon">dashboard</v-icon>
            <template v-slot:activator>
              <v-list-item-title class="text-left">Breakroom Dashboard</v-list-item-title>
            </template>

            <v-list dense >
              <v-list-item @click="goToPage('/breakroomdashboard/board')">
                <v-list-item-icon>
                  <v-icon>dashboard</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title class="text-left">View</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="goToPage('/breakroomdashboard/control')" dense>
                <v-list-item-icon>
                  <v-icon>control_camera</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title class="text-left">Edit</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-list-group>

          <!-- Breakroom Dashboard Non-Admin Menu-->
          <v-list-item v-else @click="goToPage('/breakroomdashboard/board')">
            <v-list-item-icon>
              <v-icon>dashboard</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="text-left">Breakroom Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>            


          <div class="empty-list-space"></div>
          <div v-if="isAdmin">
            <v-list-item v-for="item in adminAccountNavItems" :key="item.title" @click="goToPage(item.url)">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-left">{{ item.title }}</v-list-item-title>
            </v-list-item>
          </div>
          <v-list-item v-for="item in accountItems" :key="item.icon" @click="goToPage(item.url)">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="text-left">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      </v-list>


    </v-navigation-drawer>
  </div>
</template>

<script>
import colors from "@/colors";
import roleIdentifier from "@/roleIdentifier";
import userDataAccess from "@/dataAccess/userDataAccess";
import roleDataAccess from "@/dataAccess/roleDataAccess";
import profilePictureDataAccess from "@/dataAccess/profilePictureDataAccess";

export default {
  name: 'navbar',
  data() {
    return {
      colors,
      showSideMenu: true,
      engineerNavItems: [
        { title: "My Dashboard", icon: "dashboard", url: "/" },
        { title: "My Skills", icon: "school", url: "/skills" },
        { title: "My Certifications", icon: "bubble_chart", url: "/certifications" }
      ],
      adminSkillNavItems: [
          { title: "Manage Skills", icon: "gavel", url: "/manageskills" }
      ],
      certificationManagerNavItems: [
        { title: "Manage Certifications", icon: "account_balance", url: "/certificationmanagement"}
      ],
      allUserNavItems: [
        { title: "Search by Skill", icon: "search", url: "/searchbyskill" },
        {
          title: "Search for Engineers",
          icon: "group",
          url: "/searchforengineers"
        }
      ],
      adminAccountNavItems: [
        { title: "Manage Users", icon: "how_to_reg", url: "/manageusers" },
        { title: "Edit Seating Charts", icon: "create", url: "/editseatingchart" }
      ],
      accountItems: [
        { title: "Seating Chart", icon: "view_module", url: "/seatingchart"},
        { title: "My Account", icon: "account_box", url: "/account" },
        { title: "Logout", icon: "exit_to_app", url: "/logout" }
      ],
      isLoggedIn:
        localStorage.getItem("token") !== "" &&
        localStorage.getItem("token") !== null,
      userDAO: new userDataAccess(),
      user: {
        email: "",
        name: "",
        isEnabled: true
      },
      userRoles: [],
      roleDAO: new roleDataAccess(),
      profilePictureDAO: new profilePictureDataAccess(),
      profileImage: null
    };
  },
  methods: {
    goToPage(page) {
      this.$router.push(page);
    },
    async initUser() {      
      try {
        this.user = await this.userDAO.getAccount(null);
      } catch (err) {
        console.log(err);
        return;
      }
    },
    async initRoles() {
      try {
        this.userRoles = await this.roleDAO.getRoles();
      } catch (err) {
        console.log(err);
        return;
      }
    },
    async initProfilePicture() {
      try{
        this.profileImage = await this.profilePictureDAO.getProfilePicture();
      }catch (error) {
        console.log(error);
      }
    }
  },
  computed: {
    isMini() {
      switch(this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm': 
          return true; 
        case 'md': 
        case 'lg': 
        case 'xl':
        default: 
          return false;
      }
    },
    title() {
      return this.$route.name;
    },
    isAdmin() {
      return roleIdentifier.amIAdmin();
    },
    isEngineer() {
      return roleIdentifier.amIEngineer();
    },
    isDashboardAdministrator() {
      return roleIdentifier.amIDashboardAdmin();
    },
    isCertificationManager(){
      return roleIdentifier.amICertificationManager();
    }
  },
  created: async function() {
    if(!window.location.href.includes("accessToken")){
      await this.initUser();
      await this.initRoles();
      await this.initProfilePicture();
    }
  }
};
</script>

<style>
.toolbar-container {
  text-align: left;
  position: relative;
  z-index: 0;
  background-color: white;
}

@media screen and (min-width: 1300px) {
  .toolbar-container {
    display: none;
  }
}

.v-navigation-drawer--fixed {
  z-index: 200 !important; /* fixes the nav-drawer covering the drop-in calandar */
}

.primary--text {
    color: white !important;
}
/* .v-icon.mdi.mdi-chevron-down.theme--light{
   color: white;
 } */

.empty-list-space{
  height: 25px;
}
</style>

<style scoped>
  .title {
    font-size: 2em;
    font-weight: bold;
  }
  .v-list__tile.v-list__tile--link{
    margin-left: 25px;
  }

  .v-toolbar-content {
    padding-left: 4px;
  }

  .bordered {
    border: 1px solid #432580;
  }
</style>
