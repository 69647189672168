export const UserModule =  {
    state: {
        user: {
            email: '',
            name: '',
            isEnabled: true
        },
        userViewing: {
            email: '',
            name: '',
            isEnabled: true
        },
        allUsers: []
    },
    mutations: {
        updateUser(state, user) {
            state.user.email = user.email;
            state.user.name = user.name;
            state.user.isEnabled = user.isEnabled;
        },
        updateUserViewing(state, user){
            state.userViewing.email = user.email;
            state.userViewing.name = user.name;
            state.userViewing.isEnabled = user.isEnabled;
        },
        enableUserViewing(state) {
            state.userViewing.isEnabled = true;
        },
        disableUserViewing(state) {
            state.userViewing.isEnabled = false;
        },
        updateAllUsers(state, userList) {
            state.allUsers = userList;
        }
    },
    getters: {
        user: state => {
            return state.user;
        },
        userViewing: state => {
            return state.userViewing;
        },
        allUsers: state => {
            return state.allUsers;
        }
    }
}