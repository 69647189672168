import _ from 'lodash';

export const SkillModule =  {
    state: {
        allSkills: [],
        allSkillsGrouped: []
    },
    mutations: {
        updateAllSkills(state, skills) {
            state.allSkills.splice(0, state.allSkills.length);
            state.allSkills.push(...skills);
            state.allSkillsGrouped = _.groupBy(skills, "category.name");
        },
        addSkill(state, skill) {
            state.allSkills.push(skill);
        },
        updateSkill(state, skill) {
            state.allSkills.forEach(element => {
                if (element.id == skill.id) {
                    element.name = skill.name;
                    element.category = skill.category;
                    if(element.tags.length !== 0){
                        element.tags.splice(0, element.tags.length);
                    }
                    element.tags.push(...skill.tags);
                    return;
                }
            });
        },
        resolveSkillTags(state, modifiedTag) {
            state.allSkills.forEach(skill => {
                if (skill.category.id === modifiedTag.id) {
                    skill.category = modifiedTag;
                }
                skill.tags.forEach(tag => {
                    if (tag.id === modifiedTag.id) {
                        tag.name = modifiedTag.name;
                    }
                });
            });
        }
    },
    getters: {
        allSkillsList: state => {
            return state.allSkills;
        },
        allSkillsGrouped: state => {
            return state.allSkillsGrouped;
        },
        skill: (state) => (skill) => {
            return state.allSkills.find(k => k.id == skill.id);
        }
    }
}