import apiWrapper from "@/apiWrapper";
import store from "@/store";

class roleDataAccess {
    static cacheTimeoutMilliseconds = 1000 * 60 * 5;
    static lastGetRolesUpdate = -1;
    static lastUserRetrieved = "";
    static lastGetAvailableRolesUpdate = -1;

    async getRoles(userEmail){
        if(this.needsGetRolesRefresh(userEmail)){
            let response = await apiWrapper.getRoles(userEmail);

            roleDataAccess.lastGetRolesUpdate = new Date().getTime();
            roleDataAccess.lastUserRetrieved = userEmail;

            if(userEmail == null){
                store.commit("updateMyRoles", response.data);
            }
            else {
                store.commit("updateUserViewingRoles", response.data);
            }
        }

        if(userEmail == null){
            return store.getters.myRoles;
        }
        else {
            return store.getters.userViewingRoles;
        }
    }

    async getAvailableRoles(){
        if(this.needsGetAvailableRolesRefresh()){
            let response = await apiWrapper.getAvailableRoles();

            roleDataAccess.lastGetAvailableRolesUpdate = new Date().getTime();

            store.commit("updateAvailableRoles", response.data);
        }
        return store.getters.availableRoles;
    }

    async postUserRole(roleName, userEmail){
        await apiWrapper.postUserRole(roleName, userEmail);
        if(userEmail == null){
            store.commit("addMyRole", roleName);
            return store.getters.myRole(roleName);
        }
        else {
            store.commit("addUserViewingRole", roleName);
            return store.getters.userViewingRole(roleName);
        }
    }

    async deleteUserRole(roleName, userEmail){
        await apiWrapper.deleteUserRole(roleName, userEmail);
        if(userEmail == null){
            store.commit("removeMyRole", roleName);
        }
        else {
            store.commit("removeUserViewingRole", roleName);
        }
        roleDataAccess.lastGetAvailableRolesUpdate = 0;
        await this.getAvailableRoles();
    }

    needsGetRolesRefresh(userEmail){
        // return roleDataAccess.lastGetTagsUpdate === -1 || 
        //        roleDataAccess.lastUserRetrieved !== userEmail ||
        //        new Date().getTime() - roleDataAccess.lastGetTagsUpdate >= roleDataAccess.cacheTimeoutMilliseconds;
        var test = userEmail
        test *2
        return true
    }

    needsGetAvailableRolesRefresh(){
        // return roleDataAccess.lastGetAvailableRolesUpdate === -1 || 
        //        new Date().getTime() - roleDataAccess.lastGetAvailableRolesUpdate >= roleDataAccess.cacheTimeoutMilliseconds;
        return true
    }
}

export default roleDataAccess;