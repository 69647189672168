export const DashboardConfigModule = {
  state: {
      config: {}
  },
  mutations: {
      setConfig(state, config) {
          state.config = config;
      }
  },
  getters: {
      config: state => {
          return state.config;
      }
  }
}