const PRIMARY = "#432580";
const PRIMARY_RGB = "102,51,153";
const PRIMARY_LIGHT = "#4a298c";
const CONTENT_TEXT = "#777";
const CONTENT_SECONDARY_TEXT = "#afafaf";

const ERROR_COLOR = "#cc0000";

class colors {
  static get PRIMARY() { return PRIMARY }
  static get PRIMARY_RGB() { return PRIMARY_RGB }
  static get PRIMARY_LIGHT() { return PRIMARY_LIGHT }
  static get CONTENT_TEXT() { return CONTENT_TEXT }
  static get CONTENT_SECONDARY_TEXT() { return CONTENT_SECONDARY_TEXT }
  static get ERROR_COLOR() { return ERROR_COLOR }
}

export default colors;