export const EngineerModule =  {
    state: {
        engineers: []
    },
    mutations: {
        updateEngineerList(state, engineers) {
            state.engineers = engineers;
        }
    },
    getters: {
        engineerList: state => {
            return state.engineers;
        }
    }
}