import axios from "axios";
import authenticator from "@/authenticator.js";


const unspecifiedErrorMessage = 'A server error occurred. Please try again or contact the admistrator';

const UNAUTHORIZED_ALLOWED_URLS = [
    process.env.VUE_APP_GRAPH_API_URI,
    `${process.env}`
]

// automatically append the base API url based on the related .env files
axios.defaults.baseURL = process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : '';

// runs before any Axios request...  could use to attach Auth Tokens to request headers, trigger a loading animation, etc...
axios.interceptors.request.use(async (request) => {
    if (UNAUTHORIZED_ALLOWED_URLS.includes(request.url)) {
        return request;
    }

    // attach auth token to request headers
    const token = await new authenticator().getMSALToken();
    request.headers['Authorization'] = 'Bearer ' + token.accessToken; 
    return request
})

// Run whenever a response is returned to an axios request.  Could use for client-side error handling, etc.
axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error && error.response && error.response.status) {
        // Client-side error handling based on Status Code        

        switch (error.response.status) {
            case 400:
                break;
            case 401:
                document.getElementById("session-expired").style.display = "flex";
                break;
            case 403:                
                break;
            case 404:
                break;
            case 500:
                break;
            default:
                break;
        }
    } else {  
        // custom error handler for unspecified errors
        console.error(unspecifiedErrorMessage);
    }
});



export default axios;
